import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {formTokko,updateFields} from '../redux/contactDucks'
import { connect } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import IconRightSvg from "../images/svg/icon-right.svg";

const Contact = (props) => {
    const [text, setText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const {data} = props
    const {dispatch} = props
    const {from} = props
    const {property} = props
    const {development} = props

    const [datos, setDatos] = useState({
      nombre: "",
      cellphone: "",
      email: "",
      text: "",
      type: ""
    });

    const handleInputChange = (event) => {
        setDatos({
          ...datos,
          [event.target.name]: event.target.value,
        });
    };

    const sendForm = (event) => {
    event.preventDefault();
    if(from === 'development'){
        dispatch(formTokko(getUtm('Consulta Emprendimiento'),development.name,development.id,true))
    }
    if(from === 'property'){
        dispatch(formTokko(getUtm('Consulta Propiedad'),property.address,property.id))
    }
    if(from === 'contact'){
        dispatch(formTokko(getUtm('Consulta Web'),null,null))
    }
    document.getElementById("formContact").reset();

    return toast.success("¡Mensaje enviado correctamente!") 
    }

    useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();

    // if(!realEstate?.keys?.captcha){
    //     setDisabled(false)
    // }
    // else{
    //     setDisabled(true)
    // }

    }, [datos]);

    const getUtm = (customs = []) => {
        if(typeof window !== 'undefined'){
            var queryString = window.location.search
            var urlParams = new URLSearchParams(queryString)
            const tags = [customs]
            for (const [key, value] of urlParams) {
                if(key.includes("source")){tags.push("Origen: " + value)}
                if(key.includes("medium")){tags.push("Pieza: " + value)}
                if(key.includes("campaign")){tags.push("Campaña: " + value)}
            }
            return tags
        }
    }


    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              keys {
                captcha
              }
          }
    }`)

    return(
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-12 mb-lg-3">
                <input type="text" name="nombre" onChange={handleInputChange} placeholder="Nombre y Apellido"  />
            </div>
            <div class="col-lg-12 mb-lg-3">
                <input type="email" name="email" onChange={handleInputChange} placeholder="E-mail*" required />
            </div>
            <div class="col-lg-6 mb-lg-3 pr-lg-3">
                <input type="text" name="cellphone" onChange={handleInputChange} placeholder="Teléfono"  />
            </div>
            <div class="col-lg-6 pl-lg-3 mb-lg-3 position-relative">
                <select name="type" onChange={handleInputChange}>
                    <option value="none">Motivo</option>
                    <option value="propiedad">Consulta sobre una propiedad</option>
                    <option value="inmobiliaria">Consultoría Inmobiliaria</option>
                    <option value="generica">Otras consultas</option>
                </select>
                <i></i>
            </div>
            <div class="col-lg-12 mb-lg-3">
                <textarea name="text" cols="30" rows="5" onChange={handleInputChange} placeholder="Mensaje"></textarea>
            </div>
            <div class="col-lg-12">
                <p className="text-right">* Campos obligatorios</p>
                {
                    realEstate?.keys?.captcha ? 
                    <ReCAPTCHA
                    sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                    onChange ={() => setDisabled(false)}
                    /> 
                    : 
                    '' 
                }
                <button disabled={disabled} type="submit" id="submit-info"
                    class="btn d-flex submit justify-content-center align-items-center mt-4">
                    CONTACTANOS <label className="mb-0" htmlFor="subit-info"><IconRightSvg class="icon ms-2 mb-0"/></label>
                </button>
            </div>
        </form>
    )
}
export default connect(state => ({
    data: state.contact.data
}),null)(Contact);
