import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";
import Formulario from "../Contact";

const Team = () => {

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections {
                 about {
                    team {
                        hidden
                        title
                        paragraph
                        subtitle
                        image
                        items{
                            name
                            type
                            broker_id
                        }
                    }
                }
             }
         }
     }`)


    return (
        <section class="formulario-about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">
                        <h2>Tenés dudas? <br/>Contactanos :)</h2>
                    </div>
                    <div class="col-lg-6">
                        <Formulario from={'contact'} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team;