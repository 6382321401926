import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";

const Commitment = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    commitment{
                        title
                        paragraph
                    }
                 }

             }
         }
     }`)

    const mockRealEstate = {
        sections: {
            about: {
                commitment: {
                    title: ["Buscamos constantemente facilitar", "la experiencia de nuestros clientes."],
                    steps: [
                        {
                            id: 1,
                            title: "Profesionales con experiencia",
                            text: "En todas nuestras áreas y especialmente a la hora de determinar el valor de un inmueble, el primer paso para una operación exitosa y eficiente en tiempo y dinero."
                        },
                        {
                            id: 2,
                            title: "Oferta de inmuebles",
                            text: "Publicaciones Premium en los mejores portales del sector, producciones audiovisuales profesionales y el exclusivo sistema SMS inmobiliario para lograr la máxima exposición."
                        },
                        {
                            id: 3,
                            title: "Búsqueda personalizada",
                            text: "Nuestro servicio de asesoramiento busca la satisfacción total de nuestros clientes, ninguna necesidad es un impedimento para nosotros, si no contamos con el inmueble ideal en nuestra cartera lo encontraremos!"
                        },
                        {
                            id: 4,
                            title: "Negociaciones ganadoras",
                            text: "Somos expertos en el arte de acercar a las partes. Cada reserva es un nuevo desafío para lograr el éxito del negocio.  Tenemos en cuenta todas las variables para asesorar seriamente a los clientes y alcanzar la satisfacción total."
                        },
                        {
                            id: 5,
                            title: "Postventa",
                            text: "Vamos siempre más allá acompañando a las partes en todo momento, dando soporte y asesoramiento hasta la firma de la escritura y posteriormente con el seguimiento de la entrega/toma de posesión."
                        },
                    ]
                }
            }
        }
    }

    return (
        <section class="about-sale">
            <div class="container-fluid">
                <h3>
                    {mockRealEstate.sections.about.commitment.title[0]} <br/>
                    {mockRealEstate.sections.about.commitment.title[1]}
                </h3>
                <div class="row no-gutters">
                    {mockRealEstate.sections.about.commitment.steps.map((step) => (
                        <div class="col-lg col-12 pr-lg-5" key={step.id}>
                            <div class="step">
                                <div class="header-step">
                                    <div class="align-height d-flex align-item-center justify-content-start align-items-end">
                                        <span>{step.id}</span>
                                        <p>{step.title}</p>
                                    </div>
                                </div>
                                <div class="body-step">
                                    <p>{step.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Commitment;