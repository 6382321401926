import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
// import { getBrText } from "../../helpers/helper.rendering";

import RoundBgbSvg from "../../images/svg/round-bgb.svg";
import BBgbSvg from "../../images/svg/b-bgb.svg";
import MariaImg from "../../images/Maria.jpg";
import GustavoImg from "../../images/Gustavo.jpg";
import FernandoImg from "../../images/Fernando.jpg";
import MarianoImg from "../../images/Mariano.jpg";
import MailtoIconPng from "../../images/mailto.png";

const History = () => {
    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             name
             sections{
                 about{
                    items{
                        data{
                            id
                            value
                        }
                        images
                    }
                 }

             }
         }
     }`)

    const mockRealEstate = {
        sections: {
            about: {
                title: ["30 años revolucionando", "el mercado inmobiliario."],
                text: ["En 1990 la familia Bellagamba irrumpe en el rubro inmobiliario de la Zona Norte del Gran Buenos Aires con un único y claro objetivo: revolucionar el mercado con herramientas y conceptos innovadores nunca antes aplicados. Poco tiempo después logra posicionarse como la empresa líder en la zona. Hoy, con mas de 200.000 operaciones concretadas y muchas más personas satisfechas y agradecidas, la empresa sigue creciendo rompiendo paradigmas en su modelo de negocio, sumando cada día más profesionales al equipo, brindando nuevos servicios inmobiliarios vinculados al desarrollo de nuevos emprendimientos, y generando más trabajo en nuestra comunidad.", "Contamos con personal altamente calificado para brindar seguridad y transparencia en toda la operatoria de compraventa.  Acompañamos profesionalmente y con gran empatía a vendedores y compradores desde el primer instante hasta el momento último de la escrituración y más allá, asegurándonos que todo resulte perfecto. Claro que por eso nos eligen, pero no nos conformamos, queremos ser cada día mejores, mas eficientes y mas innovadores. Porque sabemos que un numero uno solo puede superarse a si mismo."],
                team: [
                    {
                        img: MariaImg,
                        name: "María Inés Arizcorreta",
                        letter: "M",
                        mail: "ines@bellagamba.com"
                    },
                    {
                        img: GustavoImg,
                        name: "Gustavo Bellagamba",
                        letter: "G",
                        mail: "gustavo@bellagamba.com"
                    },
                    {
                        img: FernandoImg,
                        name: "Fernando Bellagamba",
                        letter: "F",
                        mail: "fernando@bellagamba.com"
                    },
                    {
                        img: MarianoImg,
                        name: "Mariano Bellagamba",
                        letter: "M",
                        mail: "mariano@bellagamba.com"
                    }
                ]
            }
        }
    }

    return (
        <section id="start" class="about-history">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 d-flex flex-column align-items-start">
                        <h2>{mockRealEstate.sections.about.title[0]} <br/>
                            {mockRealEstate.sections.about.title[1]}</h2>
                        <div class="logo mt-lg-auto">
                            <RoundBgbSvg class="rotating"/>
                            <BBgbSvg class="b-bgb"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p>
                            {mockRealEstate.sections.about.text[0]}
                            <br/> <br/>
                            {mockRealEstate.sections.about.text[1]}
                        </p>
                    </div>
                    <div class="col-lg-12 mt-5 pt-lg-5">
                        <div class="row">
                            {
                                mockRealEstate.sections.about.team.map((member, index) => (
                                    <div class="col-lg-3 text-center" key={index}>
                                        <a href={`mailto:${member.mail}`}>
                                            <div class="card-person">
                                                <img src={member.img} alt={member.name}/>
                                                <div class="letra">{member.letter}</div>
                                                <div class="opacity"></div>
                                                <img class="mailto" src={MailtoIconPng} 
                                                    alt={`mailto:${member.mail}`}></img>
                                            </div>
                                            <h5>{member.name}</h5>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default History