import React, {useEffect} from "react";
import Breadcrumbs from "../breadcrumbs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText, scrollToElement } from "../../helpers/helper.rendering";
import IconRightSvg from "../../images/svg/icon-right.svg"
import nosotrosImg from "../../images/nosotros-main.png";

const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            about_us
            sections{
                about{
                    image
                }
            }
        }
    }`)
    
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const mockRealState = {
        sections: {
            about: {
                years: ["1990", "2021"],
                titleSmall: "Más de 30 años de",
                titleMain: "Marketing inmobiliario",
                subtitle: ["Buscando la satisfacción total de", "nuestros clientes y cumpliendo los", "sueños de miles de familias."],
                image: nosotrosImg
            }
        }
    }

    return (
        <section id="main" class="about-main banner-main">
            <div class="container-fluid px-0">
                <div class="row align-items-center">
                    <div class="col-lg-6 px-5 px-lg-0">
                        <div class="half-container">
                            <Breadcrumbs
                                props={[
                                { name: "Home", route: "/", location: "" },
                                { name: "Nosotros", route:   "", location: ""},
                                ]}
                            />
                            <h2>
                                {mockRealState.sections.about.years[0]} 
                                <span> —— </span>
                                {mockRealState.sections.about.years[1]}
                            </h2>
                            <h4 class="mt-3"> {mockRealState.sections.about.titleSmall} </h4>
                            <h1>{mockRealState.sections.about.titleMain}</h1>
                            <h5 class="mt-lg-2 d-block">
                                {mockRealState.sections.about.subtitle[0]} <br/>
                                {mockRealState.sections.about.subtitle[1]} <br/>
                                {mockRealState.sections.about.subtitle[2]} <br/>
                            </h5>
                        </div>
                    </div>
                    <div class="col-lg-6 px-lg-0 d-none d-lg-block">
                        <img src={mockRealState.sections.about.image} alt="Somos Bellagamba"/>
                    </div>
                </div>
                <button class="goto" 
                    onClick={() => scrollToElement("section#start.about-history")}>
                    <span>
                        <IconRightSvg class="icon"/>
                    </span>
                </button>
            </div>
        </section>
    )
} 

export default Main;